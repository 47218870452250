import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({
  userId,
  setUserID,
  username,
  setUserName,
  userEmail,
  setUserEmail,
  userRole,
  setUserRole,
  userStatus,
  setUserStatus,
  userCreatedDateStart,
  setUserCreatedDateStart,
  userCreatedDateEnd,
  setUserCreatedDateEnd,
  userType,
  setUserType,
  onSubmit,
  onChangeHOC,
  handleState,
  roleList,
}) {


  const handleClear = () => {
    setUserID("");
    setUserName("");
    setUserEmail("");
    setUserRole("");
    setUserStatus("");
    setUserType("");
    setUserCreatedDateStart("");
    setUserCreatedDateEnd("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (userId) {
      queryString += `&id=${userId}`;
    }
    if (username) {
      queryString += `&name=${username}`;
    }
    if (userEmail) {
      queryString += `&email=${userEmail}`;
    }
    if (userRole) {
      queryString += `&role=${userRole}`;
    }
    if (userStatus) {
      queryString += `&status=${userStatus}`;
    }
    if (userCreatedDateStart) {
      queryString += `&created_at_start=${userCreatedDateStart}`;
    }
    if (userCreatedDateEnd) {
      queryString += `&created_at_end=${userCreatedDateEnd}`;
    }
    if (userType) {
      queryString += `&platform=${userType}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="manage-user-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="userType">User Type</Label>
                <Input
                  placeholder="Enter User Type"
                  name="userType"
                  id="userType"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userRole">User Role</Label>
                <Input
                  type="select"
                  name="userRole"
                  id="userRole"
                  value={userRole}
                  onChange={(e) => setUserRole(e.target.value)}
                >
                  <option disabled value="">
                    Select Role
                  </option>
                  {roleList.map((role) => (
                    <option key={role.id} value={role.display_name}>
                      {role.display_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userStatus">User Status</Label>
                <Input
                  type="select"
                  name="userStatus"
                  id="userStatus"
                  value={userStatus}
                  onChange={(e) => setUserStatus(e.target.value)}
                >
                  <option disabled value="">
                    Select Status
                  </option>
                  <option>active</option>
                  <option>inactive</option>
                  <option>suspended</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userId">User ID</Label>
                <Input
                  placeholder="Enter User ID"
                  name="userID"
                  id="userId"
                  value={userId}
                  onChange={(e) => setUserID(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="username">Username</Label>
                <Input
                  placeholder="Enter Username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input
                  placeholder="Enter Email"
                  name="userEmail"
                  id="userEmail"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userCreatedDateStart">Created Date (From)</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="userCreatedDateStart"
                  id="userCreatedDateStart"
                  value={userCreatedDateStart}
                  onChange={(e) => setUserCreatedDateStart(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userCreatedDateEnd">Created Date (To)</Label>
                <Input
                  placeholder="Select Date"
                  type="date"
                  name="userCreatedDateEnd"
                  id="userCreatedDateEnd"
                  value={userCreatedDateEnd}
                  onChange={(e) => setUserCreatedDateEnd(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Search",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("userListPages", 1);
            },
          },
        ]}
      />
    </>
  );
}

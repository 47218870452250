import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({ logID,
  setLogID,
  userID,
  setUserID,
  actions,
  setActions,
  ipAddress,
  setIP,
  timestamp,
  setTime,
  onSubmit, 
  onChangeHOC, 
  handleState }) {
  

  const handleClear = () => {
    setTime("");
    setLogID("");
    setUserID("");
    setActions("");
    setIP("");
  };

  const generateQuery = () => {
    let queryString = "";

    if (logID) {
      queryString += `&log_id=${logID}`;
    }
    if (userID) {
      queryString += `&user_id=${userID}`;
    }
    if (actions) {
      queryString += `&actions=${actions}`;
    }
    if (ipAddress) {
      queryString += `&ip_address=${ipAddress}`;
    }
    if (timestamp) {
      queryString += `&timestamp=${timestamp}`;
    }

    return queryString;
  };

  return (
    <>
      <CustomModalBody>
        <Form id="billing-filter-form">
          <Row>
            <Col lg={3}>
              <FormGroup>
                <Label for="timestamp">Timestamp </Label>
                <Input
                  type="date"
                  placeholder="Enter Timestamp"
                  name="timestamp"
                  id="timestamp"
                  value={timestamp}
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="logID">Log ID </Label>
                <Input
                  placeholder="Enter Log ID"
                  name="logID"
                  id="logID"
                  value={logID}
                  onChange={(e) => {
                    setLogID(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="userID">User ID </Label>
                <Input
                  placeholder="Enter User ID"
                  name="userID"
                  id="userID"
                  value={userID}
                  onChange={(e) => {
                    setUserID(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="actions">Actions </Label>
                <Input
                  placeholder="Enter Actions"
                  name="actions"
                  id="actions"
                  value={actions}
                  onChange={(e) => {
                    setActions(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <Label for="ipAddress">IP Address </Label>
                <Input
                  placeholder="Enter IP Address"
                  name="ipAddress"
                  id="ipAddress"
                  value={ipAddress}
                  onChange={(e) => {
                    setIP(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "danger",
            outline: true,
            content: "Clear All",
            onClick: () => handleClear(),
          },
          {
            color: "primary",
            content: "Submit",
            onClick: () => {
              const queryString = generateQuery();
              onSubmit(queryString);
              handleState({ filterQuery: queryString });
              onChangeHOC("showFilterModal", false);
              onChangeHOC("advertiserPages", 1);
            },
          },
        ]}
      />
    </>
  );
}
